import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { ThemeService } from 'app/shared/services/theme.service';

@Component({
  selector: 'app-settings',
  styleUrls: ['./settings.component.scss'],
  templateUrl: './settings.component.html',
  encapsulation: ViewEncapsulation.None,
  exportAs: 'appSettings'
})
export class SettingsComponent implements OnInit, OnDestroy {
  @ViewChild('settingsDrawer') settingsDrawer;

  public config: any;
  public scheme: 'dark' | 'light';
  public theme: string;
  public themes: any;

  private unsubscribe$ = new Subject<any>();

  constructor(
    private themeService: ThemeService
  ) {
  }

  ngOnInit(): void {
    this.themeService.config$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((config: any) => this.config = config);
  }

  public toggle(): void {
    this.settingsDrawer.toggle();
  }

  public setScheme(scheme: any): void {
    this.themeService.config = {scheme};
  }

  public setTheme(theme: any): void {
    this.themeService.config = {theme};
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
