import { Injectable } from '@angular/core';

import { DrawerComponent } from './drawer.component';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {
  private componentRegistry: Map<string, DrawerComponent> = new Map<string, DrawerComponent>();

  constructor() {
  }

  public registerComponent(name: string, component: DrawerComponent): void {
    this.componentRegistry.set(name, component);
  }

  public deregisterComponent(name: string): void {
    this.componentRegistry.delete(name);
  }

  public getComponent(name: string): DrawerComponent | undefined {
    return this.componentRegistry.get(name);
  }
}
