import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { UtilsService } from 'app/shared/services/utils.service';
import { NavigationComponent } from '../../navigation.component';
import { NavigationService } from '../../navigation.service';
import { INavigationItem } from '../../navigation.types';

@Component({
  selector: 'app-navigation-basic-item',
  templateUrl: './basic.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationBasicItemComponent implements OnInit, OnDestroy {
  @Input() item: INavigationItem;
  @Input() name: string;

  public isActiveMatchOptions: IsActiveMatchOptions;

  private appNavigationComponent: NavigationComponent;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private navigationService: NavigationService,
    private utilsService: UtilsService
  ) {
    this.isActiveMatchOptions = this.utilsService.subsetMatchOptions;
  }

  ngOnInit(): void {
    this.isActiveMatchOptions =
      this.item.isActiveMatchOptions ?? this.item.exactMatch
        ? this.utilsService.exactMatchOptions
        : this.utilsService.subsetMatchOptions;

    this.appNavigationComponent = this.navigationService.getComponent(this.name);

    this.changeDetectorRef.markForCheck();

    this.appNavigationComponent.onRefreshed.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
