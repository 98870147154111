<div
  [class.app-navigation-item-has-subtitle]="!!item.subtitle"
  [ngClass]="item.classes?.wrapper"
  class="app-navigation-item-wrapper">

  <ng-container *ngIf="item.link && !item.externalLink && !item.function && !item.disabled">
    <a
      [matTooltip]="item.tooltip || ''"
      [ngClass]="{'app-navigation-item-active-forced': item.active}"
      [routerLinkActiveOptions]="isActiveMatchOptions"
      [routerLinkActive]="'app-navigation-item-active'"
      [routerLink]="[item.link]"
      class="app-navigation-item">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngIf="item.link && item.externalLink && !item.function && !item.disabled">
    <a
      [href]="item.link"
      [matTooltip]="item.tooltip || ''"
      [target]="item.target || '_self'"
      class="app-navigation-item">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngIf="!item.link && item.function && !item.disabled">
    <div
      (click)="item.function(item)"
      [matTooltip]="item.tooltip || ''"
      [ngClass]="{'app-navigation-item-active-forced': item.active}"
      class="app-navigation-item">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="item.link && !item.externalLink && item.function && !item.disabled">
    <a
      (click)="item.function(item)"
      [matTooltip]="item.tooltip || ''"
      [ngClass]="{'app-navigation-item-active-forced': item.active}"
      [routerLinkActiveOptions]="isActiveMatchOptions"
      [routerLinkActive]="'app-navigation-item-active'"
      [routerLink]="[item.link]"
      class="app-navigation-item">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngIf="item.link && item.externalLink && item.function && !item.disabled">
    <a
      (click)="item.function(item)"
      [href]="item.link"
      [matTooltip]="item.tooltip || ''"
      [target]="item.target || '_self'"
      class="app-navigation-item">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngIf="!item.link && !item.function && !item.disabled">
    <div
      [matTooltip]="item.tooltip || ''"
      [ngClass]="{'app-navigation-item-active-forced': item.active}"
      class="app-navigation-item">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="item.disabled">
    <div
      [matTooltip]="item.tooltip || ''"
      class="app-navigation-item app-navigation-item-disabled">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>
  </ng-container>

</div>

<ng-template #itemTemplate>
  <ng-container *ngIf="item.icon">
    <mat-icon
      [ngClass]="item.classes?.icon"
      [svgIcon]="item.icon"
      class="app-navigation-item-icon"></mat-icon>
  </ng-container>

  <div class="app-navigation-item-title-wrapper">
    <div class="app-navigation-item-title">
      <span [ngClass]="item.classes?.title">
        {{item.title}}
      </span>
    </div>
    <ng-container *ngIf="item.subtitle">
      <div class="app-navigation-item-subtitle">
        <span [ngClass]="item.classes?.subtitle">
          {{item.subtitle}}
        </span>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="item.badge">
    <div class="app-navigation-item-badge">
      <div
        [ngClass]="item.badge.classes"
        class="app-navigation-item-badge-content">
        {{item.badge.title}}
      </div>
    </div>
  </ng-container>
</ng-template>
