import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { debounceTime, fromEvent, Subject, takeUntil } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Platform } from '@angular/cdk/platform';
import PerfectScrollbar from 'perfect-scrollbar';
import { merge } from 'lodash-es';

import { ScrollbarGeometry, ScrollbarPosition } from './scrollbar.helpers';

@Directive({
  selector: '[appScrollbar]',
  exportAs: 'appScrollbar'
})
export class ScrollbarDirective implements OnChanges, OnInit, OnDestroy {
  @Input() appScrollbar: boolean = true;
  @Input() appScrollbarOptions: PerfectScrollbar.Options;

  public ps: PerfectScrollbar;

  private animation: number;
  private options: PerfectScrollbar.Options;
  private unsubscribe$ = new Subject<any>();

  constructor(
    public elementRef: ElementRef,
    private platform: Platform
  ) {
  }

  ngOnInit(): void {
    fromEvent(window, 'resize')
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(150)
      )
      .subscribe(() => this.update());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('appScrollbar' in changes) {
      this.appScrollbar = coerceBooleanProperty(changes.appScrollbar.currentValue);

      if (this.appScrollbar) {
        this.initPS();
      } else {
        this.destroyPS();
      }
    }

    if ('appScrollbarOptions' in changes) {
      this.options = merge({}, this.options, changes.appScrollbarOptions.currentValue);

      if (!this.ps) {
        return;
      }

      setTimeout(() => {
        this.destroyPS();
      });

      setTimeout(() => {
        this.initPS();
      });
    }
  }

  public isEnabled(): boolean {
    return this.appScrollbar;
  }

  public update(): void {
    if (!this.ps) {
      return;
    }

    this.ps.update();
  }

  public destroy(): void {
    this.ngOnDestroy();
  }

  public geometry(prefix: string = 'scroll'): ScrollbarGeometry {
    return new ScrollbarGeometry(
      this.elementRef.nativeElement[prefix + 'Left'],
      this.elementRef.nativeElement[prefix + 'Top'],
      this.elementRef.nativeElement[prefix + 'Width'],
      this.elementRef.nativeElement[prefix + 'Height']);
  }

  public position(absolute: boolean = false): ScrollbarPosition {
    let scrollbarPosition;

    if (!absolute && this.ps) {
      scrollbarPosition = new ScrollbarPosition(
        this.ps.reach.x || 0,
        this.ps.reach.y || 0
      );
    } else {
      scrollbarPosition = new ScrollbarPosition(
        this.elementRef.nativeElement.scrollLeft,
        this.elementRef.nativeElement.scrollTop
      );
    }

    return scrollbarPosition;
  }

  public scrollTo(x: number, y?: number, speed?: number): void {
    if (y == null && speed == null) {
      this.animateScrolling('scrollTop', x, speed);
    } else {
      if (x != null) {
        this.animateScrolling('scrollLeft', x, speed);
      }

      if (y != null) {
        this.animateScrolling('scrollTop', y, speed);
      }
    }
  }

  public scrollToX(x: number, speed?: number): void {
    this.animateScrolling('scrollLeft', x, speed);
  }

  public scrollToY(y: number, speed?: number): void {
    this.animateScrolling('scrollTop', y, speed);
  }

  public scrollToTop(offset: number = 0, speed?: number): void {
    this.animateScrolling('scrollTop', offset, speed);
  }

  public scrollToBottom(offset: number = 0, speed?: number): void {
    const top = this.elementRef.nativeElement.scrollHeight - this.elementRef.nativeElement.clientHeight;
    this.animateScrolling('scrollTop', top - offset, speed);
  }

  public scrollToLeft(offset: number = 0, speed?: number): void {
    this.animateScrolling('scrollLeft', offset, speed);
  }

  public scrollToRight(offset: number = 0, speed?: number): void {
    const left = this.elementRef.nativeElement.scrollWidth - this.elementRef.nativeElement.clientWidth;
    this.animateScrolling('scrollLeft', left - offset, speed);
  }

  public scrollToElement(qs: string, offset: number = 0, ignoreVisible: boolean = false, speed?: number): void {
    const element = this.elementRef.nativeElement.querySelector(qs);

    if (!element) {
      return;
    }

    const elementPos = element.getBoundingClientRect();
    const scrollerPos = this.elementRef.nativeElement.getBoundingClientRect();

    if (this.elementRef.nativeElement.classList.contains('ps--active-x')) {
      if (ignoreVisible && elementPos.right <= (scrollerPos.right - Math.abs(offset))) {
        return;
      }

      const currentPos = this.elementRef.nativeElement['scrollLeft'];
      const position = elementPos.left - scrollerPos.left + currentPos;

      this.animateScrolling('scrollLeft', position + offset, speed);
    }

    if (this.elementRef.nativeElement.classList.contains('ps--active-y')) {
      if (ignoreVisible && elementPos.bottom <= (scrollerPos.bottom - Math.abs(offset))) {
        return;
      }

      const currentPos = this.elementRef.nativeElement['scrollTop'];
      const position = elementPos.top - scrollerPos.top + currentPos;

      this.animateScrolling('scrollTop', position + offset, speed);
    }
  }

  public animateScrolling(target: string, value: number, speed?: number): void {
    if (this.animation) {
      window.cancelAnimationFrame(this.animation);
      this.animation = null;
    }

    if (!speed || typeof window === 'undefined') {
      this.elementRef.nativeElement[target] = value;
    } else if (value !== this.elementRef.nativeElement[target]) {
      let newValue = 0;
      let scrollCount = 0;

      let oldTimestamp = performance.now();
      let oldValue = this.elementRef.nativeElement[target];

      const cosParameter = (oldValue - value) / 2;

      const step = (newTimestamp: number): void => {
        scrollCount += Math.PI / (speed / (newTimestamp - oldTimestamp));
        newValue = Math.round(value + cosParameter + cosParameter * Math.cos(scrollCount));

        if (this.elementRef.nativeElement[target] === oldValue) {
          if (scrollCount >= Math.PI) {
            this.animateScrolling(target, value, 0);
          } else {
            this.elementRef.nativeElement[target] = newValue;

            oldValue = this.elementRef.nativeElement[target];
            oldTimestamp = newTimestamp;

            this.animation = window.requestAnimationFrame(step);
          }
        }
      };

      window.requestAnimationFrame(step);
    }
  }

  ngOnDestroy(): void {
    this.destroyPS();

    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private initPS(): void {
    if (this.ps) {
      return;
    }

    if (this.platform.ANDROID || this.platform.IOS || !this.platform.isBrowser) {
      this.appScrollbar = false;
      return;
    }

    this.ps = new PerfectScrollbar(this.elementRef.nativeElement, {...this.options});
  }

  private destroyPS(): void {
    if (!this.ps) {
      return;
    }

    this.ps.destroy();
    this.ps = null;
  }
}
