import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';

import { animations } from 'app/shared/animations';

import { NavigationComponent } from '../../navigation.component';
import { NavigationService } from '../../navigation.service';
import { INavigationItem } from '../../navigation.types';

@Component({
  selector: 'app-navigation-collapsable-item',
  templateUrl: './collapsable.component.html',
  animations: animations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationCollapsableItemComponent implements OnInit, OnDestroy {
  @Input() autoCollapse: boolean;
  @Input() item: INavigationItem;
  @Input() name: string;

  public isCollapsed: boolean = true;
  public isExpanded: boolean = false;

  private appNavigationComponent: NavigationComponent;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private navigationService: NavigationService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) {
  }

  @HostBinding('class') get classList(): any {
    return {
      'app-navigation-item-collapsed': this.isCollapsed,
      'app-navigation-item-expanded': this.isExpanded
    };
  }

  ngOnInit(): void {
    this.appNavigationComponent = this.navigationService.getComponent(this.name);

    if (this.hasActiveChild(this.item, this.router.url)) {
      this.expand();
    } else {
      if (this.autoCollapse) {
        this.collapse();
      }
    }

    this.appNavigationComponent.onCollapsableItemCollapsed
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((collapsedItem) => {
        if (collapsedItem === null) {
          return;
        }

        if (this.isChildrenOf(collapsedItem, this.item)) {
          this.collapse();
        }
      });

    if (this.autoCollapse) {
      this.appNavigationComponent.onCollapsableItemExpanded
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((expandedItem) => {
          if (expandedItem === null) {
            return;
          }

          if (this.isChildrenOf(this.item, expandedItem)) {
            return;
          }

          if (this.hasActiveChild(this.item, this.router.url)) {
            return;
          }

          if (this.item === expandedItem) {
            return;
          }

          this.collapse();
        });
    }

    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event: NavigationEnd) => {
        if (this.hasActiveChild(this.item, event.urlAfterRedirects)) {
          this.expand();
        } else {
          if (this.autoCollapse) {
            this.collapse();
          }
        }
      });

    this.appNavigationComponent.onRefreshed.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  public collapse(): void {
    if (this.item.disabled) {
      return;
    }

    if (this.isCollapsed) {
      return;
    }

    this.isCollapsed = true;
    this.isExpanded = !this.isCollapsed;

    this.changeDetectorRef.markForCheck();

    this.appNavigationComponent.onCollapsableItemCollapsed.next(this.item);
  }

  public expand(): void {
    if (this.item.disabled) {
      return;
    }

    if (!this.isCollapsed) {
      return;
    }

    this.isCollapsed = false;
    this.isExpanded = !this.isCollapsed;

    this.changeDetectorRef.markForCheck();

    this.appNavigationComponent.onCollapsableItemExpanded.next(this.item);
  }

  public toggleCollapsable(): void {
    if (this.isCollapsed) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  public trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private hasActiveChild(item: INavigationItem, currentUrl: string): boolean {
    const children = item.children;

    if (!children) {
      return false;
    }

    for (const child of children) {
      if (child.children) {
        if (this.hasActiveChild(child, currentUrl)) {
          return true;
        }
      }

      if (child.link && this.router.isActive(child.link, child.exactMatch || false)) {
        return true;
      }
    }

    return false;
  }

  private isChildrenOf(parent: INavigationItem, item: INavigationItem): boolean {
    const children = parent.children;

    if (!children) {
      return false;
    }

    if (children.indexOf(item) > -1) {
      return true;
    }

    for (const child of children) {
      if (child.children) {
        if (this.isChildrenOf(child, item)) {
          return true;
        }
      }
    }

    return false;
  }
}
