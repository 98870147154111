import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { IDrawerMode, IDrawerPosition } from './drawer.types';
import { UtilsService } from 'app/shared/services/utils.service';
import { DrawerService } from './drawer.service';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'appDrawer'
})
export class DrawerComponent implements OnChanges, OnInit, OnDestroy {
  @Input() fixed: boolean = false;
  @Input() mode: IDrawerMode = 'side';
  @Input() name: string = this.utilsService.randomId();
  @Input() opened: boolean = false;
  @Input() position: IDrawerPosition = 'left';
  @Input() transparentOverlay: boolean = false;
  @Output() readonly fixedChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly modeChanged: EventEmitter<IDrawerMode> = new EventEmitter<IDrawerMode>();
  @Output() readonly openedChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly positionChanged: EventEmitter<IDrawerPosition> = new EventEmitter<IDrawerPosition>();

  private animationsEnabled: boolean = false;
  private hovered: boolean = false;
  private overlay: HTMLElement;
  private player: AnimationPlayer;


  constructor(
    private animationBuilder: AnimationBuilder,
    private drawerService: DrawerService,
    private utilsService: UtilsService,
    private elementRef: ElementRef,
    private renderer2: Renderer2
  ) {
  }

  @HostBinding('class') get classList(): any {
    return {
      'app-drawer-animations-enabled': this.animationsEnabled,
      'app-drawer-fixed': this.fixed,
      'app-drawer-hover': this.hovered,
      [`app-drawer-mode-${this.mode}`]: true,
      'app-drawer-opened': this.opened,
      [`app-drawer-position-${this.position}`]: true
    };
  }

  @HostBinding('style') get styleList(): any {
    return {
      'visibility': this.opened ? 'visible' : 'hidden'
    };
  }

  @HostListener('mouseenter')
  private onMouseenter(): void {
    this.enableAnimations();
    this.hovered = true;
  }

  @HostListener('mouseleave')
  private onMouseleave(): void {
    this.enableAnimations();
    this.hovered = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('fixed' in changes) {
      this.fixed = coerceBooleanProperty(changes.fixed.currentValue);

      this.fixedChanged.next(this.fixed);
    }

    if ('mode' in changes) {
      const previousMode = changes.mode.previousValue;
      const currentMode = changes.mode.currentValue;

      this.disableAnimations();

      if (previousMode === 'over' && currentMode === 'side') {
        this.hideOverlay();
      }

      if (previousMode === 'side' && currentMode === 'over') {
        if (this.opened) {
          this.showOverlay();
        }
      }

      this.modeChanged.next(currentMode);

      setTimeout(() => {
        this.enableAnimations();
      }, 500);
    }

    if ('opened' in changes) {
      const open = coerceBooleanProperty(changes.opened.currentValue);
      this.toggleOpened(open);
    }

    if ('position' in changes) {
      this.positionChanged.next(this.position);
    }

    if ('transparentOverlay' in changes) {
      this.transparentOverlay = coerceBooleanProperty(changes.transparentOverlay.currentValue);
    }
  }

  ngOnInit(): void {
    this.drawerService.registerComponent(this.name, this);
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.finish();
    }

    this.drawerService.deregisterComponent(this.name);
  }

  public open(): void {
    if (this.opened) {
      return;
    }

    this.toggleOpened(true);
  }

  close(): void {
    if (!this.opened) {
      return;
    }

    this.toggleOpened(false);
  }

  toggle(): void {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  private enableAnimations(): void {
    if (this.animationsEnabled) {
      return;
    }

    this.animationsEnabled = true;
  }

  private disableAnimations(): void {
    if (!this.animationsEnabled) {
      return;
    }

    this.animationsEnabled = false;
  }

  private showOverlay(): void {
    this.overlay = this.renderer2.createElement('div');

    if (!this.overlay) {
      return;
    }

    this.overlay.classList.add('app-drawer-overlay');

    if (this.fixed) {
      this.overlay.classList.add('app-drawer-overlay-fixed');
    }

    if (this.transparentOverlay) {
      this.overlay.classList.add('app-drawer-overlay-transparent');
    }

    this.renderer2.appendChild(this.elementRef.nativeElement.parentElement, this.overlay);

    this.player = this.animationBuilder.build([
      style({opacity: 0}),
      animate('300ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({opacity: 1}))
    ]).create(this.overlay);

    this.player.onDone(() => {
      this.player.destroy();
      this.player = null;
    });

    this.player.play();

    this.overlay.addEventListener('click', () => {
      this.close();
    });
  }

  private hideOverlay(): void {
    if (!this.overlay) {
      return;
    }

    this.player = this.animationBuilder.build([
      animate('300ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({opacity: 0}))
    ]).create(this.overlay);

    this.player.play();

    this.player.onDone(() => {
      this.player.destroy();
      this.player = null;

      if (this.overlay) {
        this.overlay.parentNode.removeChild(this.overlay);
        this.overlay = null;
      }
    });
  }

  private toggleOpened(open: boolean): void {
    this.opened = open;
    this.enableAnimations();

    if (this.mode === 'over') {
      if (open) {
        this.showOverlay();
      } else {
        this.hideOverlay();
      }
    }

    this.openedChanged.next(open);
  }
}
