<div class="w-full">

  <div class="flex flex-col divide-y border-t border-b">
    <ng-container *ngFor="let member of members;">
      <div class="flex flex-col sm:flex-row sm:items-center py-6">
        <div class="flex items-center">
          <div class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
            <ng-container *ngIf="member.avatar">
              <img
                class="object-cover w-full h-full"
                [src]="member.avatar"
                alt="Contact avatar" />
            </ng-container>
            <ng-container *ngIf="!member.avatar">
              <div
                class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                {{member.name.charAt(0)}}
              </div>
            </ng-container>
          </div>
          <div class="ml-4">
            <div class="font-medium">{{member.name}}</div>
            <div class="text-secondary">{{member.email}}</div>
          </div>
        </div>
        <div class="flex items-center mt-4 sm:mt-0 sm:ml-auto">
          <div class="order-2 sm:order-1 ml-4 sm:ml-0">
            <mat-form-field class="app-mat-dense app-mat-no-subscript w-32" appearance="fill">
              <mat-select
                [panelClass]="'w-72 min-w-72 max-w-72 h-auto max-h-none'"
                [value]="member.role"
                disableOptionCentering
                #roleSelect="matSelect">
                <mat-select-trigger class="text-md">
                  <span>Role:</span>
                  <span class="ml-1 font-medium">{{roleSelect.value | titlecase}}</span>
                </mat-select-trigger>
                <ng-container *ngFor="let role of roles">
                  <mat-option
                    class="h-auto py-4 leading-none"
                    [value]="role.value">
                    <div class="font-medium">{{role.label}}</div>
                    <div
                      class="mt-1.5 text-sm whitespace-normal leading-normal text-secondary">{{role.description}}</div>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="order-1 sm:order-2 sm:ml-3">
            <button mat-icon-button>
              <mat-icon
                class="text-hint"
                [svgIcon]="'heroicons_outline:trash'"></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

</div>
