import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { MatButton } from '@angular/material/button';
import { TemplatePortal } from '@angular/cdk/portal';
import { Subject, takeUntil } from 'rxjs';

import { NotificationsService } from './notifications.service';
import { INotification } from './notifications.types';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'appNotifications'
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @ViewChild('notificationsOrigin') private notificationsOrigin: MatButton;
  @ViewChild('notificationsPanel') private notificationsPanel: TemplateRef<any>;

  public notifications: INotification[];
  public unreadCount: number = 0;

  private overlayRef: OverlayRef;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private notificationsService: NotificationsService,
    private changeDetectorRef: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay
  ) {
  }

  ngOnInit(): void {
    this.notificationsService.notifications$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((notifications: INotification[]) => {
        this.notifications = notifications;

        this.calculateUnreadCount();

        this.changeDetectorRef.markForCheck();
      });
  }

  openPanel(): void {
    if (!this.notificationsPanel || !this.notificationsOrigin) {
      return;
    }

    if (!this.overlayRef) {
      this.createOverlay();
    }

    this.overlayRef.attach(new TemplatePortal(this.notificationsPanel, this.viewContainerRef));
  }


  public closePanel(): void {
    this.overlayRef.detach();
  }


  public markAllAsRead(): void {
    this.notificationsService.markAllAsRead().subscribe();
  }

  public toggleRead(notification: INotification): void {
    notification.read = !notification.read;

    this.notificationsService.update(notification.id, notification).subscribe();
  }

  public delete(notification: INotification): void {
    this.notificationsService.delete(notification.id).subscribe();
  }

  public trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();

    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  private createOverlay(): void {
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'app-backdrop-on-mobile',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.notificationsOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom'
          }
        ])
    });

    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.detach();
    });
  }

  private calculateUnreadCount(): void {
    let count = 0;

    if (this.notifications && this.notifications.length) {
      count = this.notifications.filter(notification => !notification.read).length;
    }

    this.unreadCount = count;
  }
}
