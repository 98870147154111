import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private urlMap: Map<string, boolean> = new Map<string, boolean>();
  private autoSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private modeSubject$: BehaviorSubject<'determinate' | 'indeterminate'> = new BehaviorSubject<'determinate' | 'indeterminate'>('indeterminate');
  private progressSubject$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(0);
  private showSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  get auto$(): Observable<boolean> {
    return this.autoSubject$.asObservable();
  }

  get mode$(): Observable<'determinate' | 'indeterminate'> {
    return this.modeSubject$.asObservable();
  }

  get progress$(): Observable<number> {
    return this.progressSubject$.asObservable();
  }

  get show$(): Observable<boolean> {
    return this.showSubject$.asObservable();
  }

  public show(): void {
    this.showSubject$.next(true);
  }

  public hide(): void {
    this.showSubject$.next(false);
  }

  public setAutoMode(value: boolean): void {
    this.autoSubject$.next(value);
  }

  public setMode(value: 'determinate' | 'indeterminate'): void {
    this.modeSubject$.next(value);
  }

  public setProgress(value: number): void {
    if (value < 0 || value > 100) {
      console.error('Progress value must be between 0 and 100!');
      return;
    }

    this.progressSubject$.next(value);
  }

  public setLoadingStatus(status: boolean, url: string): void {
    if (!url) {
      console.error('The request URL must be provided!');
      return;
    }

    if (status === true) {
      this.urlMap.set(url, status);
      this.showSubject$.next(true);
    } else if (status === false && this.urlMap.has(url)) {
      this.urlMap.delete(url);
    }

    if (this.urlMap.size === 0) {
      this.showSubject$.next(false);
    }
  }
}
