import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { Injectable } from '@angular/core';

import { AuthService } from '../../modules/auth/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestOptions: any = {
      withCredentials: true,
    };

    req = req.clone(requestOptions);

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 && !req.url.includes('/auth/refresh')) {
            return this.refreshToken(req, next);
          }

          if (req.url.includes('/auth/refresh')) {
            this.authService.logOut();
          }
        }

        return throwError(error);
      })
    );
  }

  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap(() => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(true);
          this.authService.setAuthenticated();
          return next.handle(request);
        }),
        catchError((error) => {
          this.isRefreshing = false;
          this.authService.logOut();
          return throwError(error);
        })
      );
    }

    return this.refreshTokenSubject.pipe(
      filter(refresh => refresh !== null),
      take(1),
      switchMap(() => next.handle(request))
    );
  }
}
