import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { filter, Observable } from 'rxjs';

import { IConfirmationConfig } from './confirmation.types';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmationDialogComponent {
  static CONFIRMED = 'confirmed';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmationConfig,
    public matDialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {
  }

  public close(): void {
    this.matDialogRef.close(ConfirmationDialogComponent.CONFIRMED);
  }
}

export const isConfirmed = () => (source$: Observable<unknown>): Observable<unknown> => source$.pipe(
  filter(result => result === ConfirmationDialogComponent.CONFIRMED)
);
