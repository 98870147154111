import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { IFSDocument, IFSDocumentElement } from './fullscreen.types';

@Component({
  selector: 'app-fullscreen',
  templateUrl: './fullscreen.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'appFullscreen'
})
export class FullscreenComponent implements OnInit {
  @Input() iconTpl: TemplateRef<any>;
  @Input() tooltip: string;

  private fsDoc: IFSDocument;
  private fsDocEl: IFSDocumentElement;
  private isFullscreen: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
    this.fsDoc = document as IFSDocument;
  }

  ngOnInit(): void {
    this.fsDocEl = document.documentElement as IFSDocumentElement;
  }

  toggleFullscreen(): void {
    this.isFullscreen = this.getBrowserFullscreenElement() !== null;

    if (this.isFullscreen) {
      this.closeFullscreen();
    } else {
      this.openFullscreen();
    }
  }

  private getBrowserFullscreenElement(): Element {
    if (typeof this.fsDoc.fullscreenElement !== 'undefined') {
      return this.fsDoc.fullscreenElement;
    }

    if (typeof this.fsDoc.mozFullScreenElement !== 'undefined') {
      return this.fsDoc.mozFullScreenElement;
    }

    if (typeof this.fsDoc.msFullscreenElement !== 'undefined') {
      return this.fsDoc.msFullscreenElement;
    }

    if (typeof this.fsDoc.webkitFullscreenElement !== 'undefined') {
      return this.fsDoc.webkitFullscreenElement;
    }

    throw new Error('Fullscreen mode is not supported by this browser');
  }

  private openFullscreen(): void {
    if (this.fsDocEl.requestFullscreen) {
      this.fsDocEl.requestFullscreen();
      return;
    }

    // Firefox
    if (this.fsDocEl.mozRequestFullScreen) {
      this.fsDocEl.mozRequestFullScreen();
      return;
    }

    // Chrome, Safari and Opera
    if (this.fsDocEl.webkitRequestFullscreen) {
      this.fsDocEl.webkitRequestFullscreen();
      return;
    }

    // IE/Edge
    if (this.fsDocEl.msRequestFullscreen) {
      this.fsDocEl.msRequestFullscreen();
      return;
    }
  }

  private closeFullscreen(): void {
    if (this.fsDoc.exitFullscreen) {
      this.fsDoc.exitFullscreen();
      return;
    }

    // Firefox
    if (this.fsDoc.mozCancelFullScreen) {
      this.fsDoc.mozCancelFullScreen();
      return;
    }

    // Chrome, Safari and Opera
    if (this.fsDoc.webkitExitFullscreen) {
      this.fsDoc.webkitExitFullscreen();
      return;
    }

    // IE/Edge
    else if (this.fsDoc.msExitFullscreen) {
      this.fsDoc.msExitFullscreen();
      return;
    }
  }
}
