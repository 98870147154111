import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { merge } from 'lodash-es';

import { ConfirmationDialogComponent } from 'app/shared/dialogs/confirm-dialog/dialog.component';
import { IConfirmationConfig } from 'app/shared/dialogs/confirm-dialog/confirmation.types';

@Injectable()
export class ConfirmationService {
  private _defaultConfig: IConfirmationConfig = {
    title: 'Confirm action',
    message: 'Are you sure you want to confirm this action?',
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation',
      color: 'warn'
    },
    actions: {
      confirm: {
        show: true,
        label: 'Confirm',
        color: 'warn'
      },
      cancel: {
        show: true,
        label: 'Cancel'
      }
    },
    dismissible: false
  };

  constructor(
    private matDialog: MatDialog
  ) {
  }

  public open(config: IConfirmationConfig = {}): MatDialogRef<ConfirmationDialogComponent> {
    const userConfig = merge({}, this._defaultConfig, config);

    return this.matDialog.open(ConfirmationDialogComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: 'app-confirmation-dialog-panel'
    });
  }
}
