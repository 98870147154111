import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { filter, Subject, takeUntil } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { UtilsService } from 'app/shared/services/utils.service';
import { IAlertAppearance, IAlertType } from './alert.types';
import { animations } from 'app/shared/animations';
import { AlertService } from './alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: animations,
  exportAs: 'appAlert'
})
export class AlertComponent implements OnChanges, OnInit, OnDestroy {
  @Input() appearance: IAlertAppearance = 'soft';
  @Input() dismissed: boolean = false;
  @Input() dismissible: boolean = false;
  @Input() name: string = this.utilsService.randomId();
  @Input() showIcon: boolean = true;
  @Input() type: IAlertType = 'primary';
  @Output() readonly dismissedChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  private unsubscribe$ = new Subject();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private alertService: AlertService,
    private utilsService: UtilsService
  ) {
  }

  @HostBinding('class') get classList(): any {
    return {
      'app-alert-appearance-border': this.appearance === 'border',
      'app-alert-appearance-fill': this.appearance === 'fill',
      'app-alert-appearance-outline': this.appearance === 'outline',
      'app-alert-appearance-soft': this.appearance === 'soft',
      'app-alert-dismissed': this.dismissed,
      'app-alert-dismissible': this.dismissible,
      'app-alert-show-icon': this.showIcon,
      'app-alert-type-primary': this.type === 'primary',
      'app-alert-type-accent': this.type === 'accent',
      'app-alert-type-warn': this.type === 'warn',
      'app-alert-type-basic': this.type === 'basic',
      'app-alert-type-info': this.type === 'info',
      'app-alert-type-success': this.type === 'success',
      'app-alert-type-warning': this.type === 'warning',
      'app-alert-type-error': this.type === 'error'
    };
  }

  ngOnInit(): void {
    this.alertService.onDismiss
      .pipe(
        filter(name => this.name === name),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.dismiss());

    this.alertService.onShow
      .pipe(
        filter(name => this.name === name),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.show());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('dismissed' in changes) {
      this.dismissed = coerceBooleanProperty(changes.dismissed.currentValue);

      this.toggleDismiss(this.dismissed);
    }

    if ('dismissible' in changes) {
      this.dismissible = coerceBooleanProperty(changes.dismissible.currentValue);
    }

    if ('showIcon' in changes) {
      this.showIcon = coerceBooleanProperty(changes.showIcon.currentValue);
    }
  }

  public dismiss(): void {
    if (this.dismissed) {
      return;
    }

    this.toggleDismiss(true);
  }

  public show(): void {
    if (!this.dismissed) {
      return;
    }

    this.toggleDismiss(false);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private toggleDismiss(dismissed: boolean): void {
    if (!this.dismissible) {
      return;
    }

    this.dismissed = dismissed;
    this.dismissedChanged.next(this.dismissed);
    this.changeDetectorRef.markForCheck();
  }
}
