import { BehaviorSubject, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IChat } from './quick-chat.types';

@Injectable({
  providedIn: 'root'
})
export class QuickChatService {
  private chat: BehaviorSubject<IChat> = new BehaviorSubject(null);
  private chats: BehaviorSubject<IChat[]> = new BehaviorSubject<IChat[]>(null);

  constructor(
    private httpClient: HttpClient
  ) {
  }

  get chat$(): Observable<IChat> {
    return this.chat.asObservable();
  }

  get chats$(): Observable<IChat[]> {
    return this.chats.asObservable();
  }

  public getChats(): Observable<any> {
    return this.httpClient.get<IChat[]>('api/apps/chat/chats').pipe(
      tap((response: IChat[]) => {
        this.chats.next(response);
      })
    );
  }

  public getChatById(id: string): Observable<any> {
    return this.httpClient.get<IChat>('api/apps/chat/chat', {params: {id}}).pipe(
      map((chat) => {
        this.chat.next(chat);
        return chat;
      }),
      switchMap((chat) => {
        if (!chat) {
          return throwError('Could not found chat with id of ' + id + '!');
        }

        return of(chat);
      })
    );
  }
}
