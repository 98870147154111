import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IShortcut } from './shortcuts.types';

@Injectable({
  providedIn: 'root'
})
export class ShortcutsService {
  private shortcuts: ReplaySubject<IShortcut[]> = new ReplaySubject<IShortcut[]>(1);

  constructor(
    private httpClient: HttpClient
  ) {
  }

  get shortcuts$(): Observable<IShortcut[]> {
    return this.shortcuts.asObservable();
  }

  public getAll(): Observable<IShortcut[]> {
    return this.httpClient.get<IShortcut[]>('api/common/shortcuts').pipe(
      tap((shortcuts) => {
        this.shortcuts.next(shortcuts);
      })
    );
  }

  public create(shortcut: IShortcut): Observable<IShortcut> {
    return this.shortcuts$.pipe(
      take(1),
      switchMap(shortcuts => this.httpClient.post<IShortcut>('api/common/shortcuts', {shortcut}).pipe(
        map((newShortcut) => {
          this.shortcuts.next([...shortcuts, newShortcut]);

          return newShortcut;
        })
      ))
    );
  }

  public update(id: string, shortcut: IShortcut): Observable<IShortcut> {
    return this.shortcuts$.pipe(
      take(1),
      switchMap(shortcuts => this.httpClient.patch<IShortcut>('api/common/shortcuts', {
        id,
        shortcut
      }).pipe(
        map((updatedShortcut: IShortcut) => {
          const index = shortcuts.findIndex(item => item.id === id);

          shortcuts[index] = updatedShortcut;

          this.shortcuts.next(shortcuts);

          return updatedShortcut;
        })
      ))
    );
  }

  public delete(id: string): Observable<boolean> {
    return this.shortcuts$.pipe(
      take(1),
      switchMap(shortcuts => this.httpClient.delete<boolean>('api/common/shortcuts', {params: {id}}).pipe(
        map((isDeleted: boolean) => {
          const index = shortcuts.findIndex(item => item.id === id);

          shortcuts.splice(index, 1);

          this.shortcuts.next(shortcuts);

          return isDeleted;
        })
      ))
    );
  }
}
