import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { delay, filter, merge, ReplaySubject, Subject, Subscription, takeUntil } from 'rxjs';
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NavigationEnd, Router } from '@angular/router';

import { INavigationAppearance, INavigationItem, INavigationMode, INavigationPosition } from './navigation.types';
import { ScrollbarDirective } from 'app/shared/directives/scrollbar/scrollbar.directive';
import { UtilsService } from 'app/shared/services/utils.service';
import { navigationMenu } from '../../../config/navigation-menu';
import { NavigationService } from './navigation.service';
import { animations } from 'app/shared/animations';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: animations,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'appNavigation'
})
export class NavigationComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() appearance: INavigationAppearance = 'default';
  @Input() autoCollapse: boolean = true;
  @Input() inner: boolean = false;
  @Input() mode: INavigationMode = 'side';
  @Input() name: string = this.utilsService.randomId();
  @Input() navigation: INavigationItem[] = navigationMenu;
  @Input() opened: boolean = true;
  @Input() position: INavigationPosition = 'left';
  @Input() transparentOverlay: boolean = false;
  @Output() readonly appearanceChanged: EventEmitter<INavigationAppearance> = new EventEmitter<INavigationAppearance>();
  @Output() readonly modeChanged: EventEmitter<INavigationMode> = new EventEmitter<INavigationMode>();
  @Output() readonly openedChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly positionChanged: EventEmitter<INavigationPosition> = new EventEmitter<INavigationPosition>();
  @ViewChild('navigationContent') private _navigationContentEl: ElementRef;

  public onCollapsableItemCollapsed: ReplaySubject<INavigationItem> = new ReplaySubject<INavigationItem>(1);
  public onCollapsableItemExpanded: ReplaySubject<INavigationItem> = new ReplaySubject<INavigationItem>(1);
  public onRefreshed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public activeAsideItemId: string | null = null;

  private scrollStrategy: ScrollStrategy = this.scrollStrategyOptions.block();
  private scrollbarDirectivesSubscription: Subscription;
  private animationsEnabled: boolean = false;
  private unsubscribe$ = new Subject<any>();
  private asideOverlay: HTMLElement;
  private hovered: boolean = false;
  private player: AnimationPlayer;
  private overlay: HTMLElement;
  private readonly handleAsideOverlayClick: any;
  private readonly handleOverlayClick: any;
  private scrollbarDirectivesList!: QueryList<ScrollbarDirective>;

  constructor(
    private scrollStrategyOptions: ScrollStrategyOptions,
    private navigationService: NavigationService,
    private changeDetectorRef: ChangeDetectorRef,
    private animationBuilder: AnimationBuilder,
    private utilsService: UtilsService,
    private elementRef: ElementRef,
    private renderer2: Renderer2,
    private router: Router
  ) {
    this.handleAsideOverlayClick = (): void => {
      this.closeAside();
    };
    this.handleOverlayClick = (): void => {
      this.close();
    };
  }

  @HostBinding('class') get classList(): any {
    return {
      'app-navigation-animations-enabled': this.animationsEnabled,
      [`app-navigation-appearance-${this.appearance}`]: true,
      'app-navigation-hover': this.hovered,
      'app-navigation-inner': this.inner,
      'app-navigation-mode-over': this.mode === 'over',
      'app-navigation-mode-side': this.mode === 'side',
      'app-navigation-opened': this.opened,
      'app-navigation-position-left': this.position === 'left',
      'app-navigation-position-right': this.position === 'right'
    };
  }

  @HostBinding('style') get styleList(): any {
    return {
      'visibility': this.opened ? 'visible' : 'hidden'
    };
  }

  @ViewChildren(ScrollbarDirective)
  set scrollbarDirectives(scrollbarDirectives: QueryList<ScrollbarDirective>) {
    this.scrollbarDirectivesList = scrollbarDirectives;

    if (scrollbarDirectives.length === 0) {
      return;
    }

    if (this.scrollbarDirectivesSubscription) {
      this.scrollbarDirectivesSubscription.unsubscribe();
    }

    this.scrollbarDirectivesSubscription =
      merge(
        this.onCollapsableItemCollapsed,
        this.onCollapsableItemExpanded
      )
        .pipe(
          takeUntil(this.unsubscribe$),
          delay(250)
        )
        .subscribe(() => {
          scrollbarDirectives.forEach((scrollbarDirective) => {
            scrollbarDirective.update();
          });
        });
  }

  @HostListener('mouseenter')
  private onMouseenter(): void {
    this.enableAnimations();

    this.hovered = true;
  }

  @HostListener('mouseleave')
  private onMouseleave(): void {
    this.enableAnimations();

    this.hovered = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('appearance' in changes) {
      this.appearanceChanged.next(changes.appearance.currentValue);
    }

    if ('inner' in changes) {
      this.inner = coerceBooleanProperty(changes.inner.currentValue);
    }

    if ('mode' in changes) {
      const currentMode = changes.mode.currentValue;
      const previousMode = changes.mode.previousValue;

      this.disableAnimations();

      if (previousMode === 'over' && currentMode === 'side') {
        this.hideOverlay();
      }

      if (previousMode === 'side' && currentMode === 'over') {
        this.closeAside();

        if (this.opened) {
          this.showOverlay();
        }
      }

      this.modeChanged.next(currentMode);

      setTimeout(() => {
        this.enableAnimations();
      }, 500);
    }

    if ('navigation' in changes) {
      this.changeDetectorRef.markForCheck();
    }

    if ('opened' in changes) {
      this.opened = coerceBooleanProperty(changes.opened.currentValue);

      this.toggleOpened(this.opened);
    }

    if ('position' in changes) {
      this.positionChanged.next(changes.position.currentValue);
    }

    if ('transparentOverlay' in changes) {
      this.transparentOverlay = coerceBooleanProperty(changes.transparentOverlay.currentValue);
    }
  }

  ngOnInit(): void {
    if (this.name === '') {
      this.name = this.utilsService.randomId();
    }

    this.navigationService.registerComponent(this.name, this);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        if (this.mode === 'over' && this.opened) {
          this.close();
        }

        if (this.mode === 'side' && this.activeAsideItemId) {
          this.closeAside();
        }
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this._navigationContentEl) {
        return;
      }

      if (!this._navigationContentEl.nativeElement.classList.contains('ps')) {
        const activeItem = this._navigationContentEl.nativeElement.querySelector('.app-navigation-item-active');

        if (activeItem) {
          activeItem.scrollIntoView();
        }
      } else {
        this.scrollbarDirectivesList.forEach((scrollbarDirective) => {
          if (!scrollbarDirective.isEnabled()) {
            return;
          }

          scrollbarDirective.scrollToElement('.app-navigation-item-active', -120, true);
        });
      }
    });
  }

  public refresh(): void {
    this.changeDetectorRef.markForCheck();
    this.onRefreshed.next(true);
  }

  public open(): void {
    if (this.opened) {
      return;
    }

    this.toggleOpened(true);
  }

  public close(): void {
    if (!this.opened) {
      return;
    }

    this.closeAside();

    this.toggleOpened(false);
  }

  public toggle(): void {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  public openAside(item: INavigationItem): void {
    if (item.disabled || !item.id) {
      return;
    }

    this.activeAsideItemId = item.id;
    this.showAsideOverlay();
    this.changeDetectorRef.markForCheck();
  }

  public closeAside(): void {
    this.activeAsideItemId = null;
    this.hideAsideOverlay();
    this.changeDetectorRef.markForCheck();
  }

  public toggleAside(item: INavigationItem): void {
    if (this.activeAsideItemId === item.id) {
      this.closeAside();
    } else {
      this.openAside(item);
    }
  }

  public trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  ngOnDestroy(): void {
    this.close();
    this.closeAside();

    this.navigationService.deregisterComponent(this.name);

    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private enableAnimations(): void {
    if (this.animationsEnabled) {
      return;
    }

    this.animationsEnabled = true;
  }

  private disableAnimations(): void {
    if (!this.animationsEnabled) {
      return;
    }

    this.animationsEnabled = false;
  }

  private showOverlay(): void {
    if (this.asideOverlay) {
      return;
    }

    this.overlay = this.renderer2.createElement('div');
    this.overlay.classList.add('app-navigation-overlay');

    if (this.transparentOverlay) {
      this.overlay.classList.add('app-navigation-overlay-transparent');
    }

    this.renderer2.appendChild(this.elementRef.nativeElement.parentElement, this.overlay);
    this.scrollStrategy.enable();

    this.player = this.animationBuilder.build([
      animate('300ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({opacity: 1}))
    ]).create(this.overlay);

    this.player.play();
    this.overlay.addEventListener('click', this.handleOverlayClick);
  }

  private hideOverlay(): void {
    if (!this.overlay) {
      return;
    }

    this.player = this.animationBuilder.build([
      animate('300ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({opacity: 0}))
    ]).create(this.overlay);

    this.player.play();

    this.player.onDone(() => {
      if (this.overlay) {
        this.overlay.removeEventListener('click', this.handleOverlayClick);
        this.overlay.parentNode.removeChild(this.overlay);
        this.overlay = null;
      }

      this.scrollStrategy.disable();
    });
  }

  private showAsideOverlay(): void {
    if (this.asideOverlay) {
      return;
    }

    this.asideOverlay = this.renderer2.createElement('div');
    this.asideOverlay.classList.add('app-navigation-aside-overlay');
    this.renderer2.appendChild(this.elementRef.nativeElement.parentElement, this.asideOverlay);

    this.player =
      this.animationBuilder
        .build([
          animate('300ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({opacity: 1}))
        ]).create(this.asideOverlay);

    this.player.play();

    this.asideOverlay.addEventListener('click', this.handleAsideOverlayClick);
  }

  private hideAsideOverlay(): void {
    if (!this.asideOverlay) {
      return;
    }

    this.player =
      this.animationBuilder
        .build([
          animate('300ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({opacity: 0}))
        ]).create(this.asideOverlay);

    this.player.play();

    this.player.onDone(() => {
      if (this.asideOverlay) {
        this.asideOverlay.removeEventListener('click', this.handleAsideOverlayClick);
        this.asideOverlay.parentNode.removeChild(this.asideOverlay);
        this.asideOverlay = null;
      }
    });
  }

  private toggleOpened(open: boolean): void {
    this.opened = open;

    this.enableAnimations();

    if (this.mode === 'over') {
      if (this.opened) {
        this.showOverlay();
      } else {
        this.hideOverlay();
      }
    }

    this.openedChanged.next(open);
  }
}
