import { Component, HostBinding, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { animations } from 'app/shared/animations';
import { ICardFace } from './card.types';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: animations,
  exportAs: 'appCard'
})
export class CardComponent implements OnChanges {
  @Input() expanded: boolean = false;
  @Input() face: ICardFace = 'front';
  @Input() flippable: boolean = false;

  constructor() {
  }

  @HostBinding('class') get classList(): any {
    return {
      'app-card-expanded': this.expanded,
      'app-card-face-back': this.flippable && this.face === 'back',
      'app-card-face-front': this.flippable && this.face === 'front',
      'app-card-flippable': this.flippable
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('expanded' in changes) {
      this.expanded = coerceBooleanProperty(changes.expanded.currentValue);
    }

    if ('flippable' in changes) {
      this.flippable = coerceBooleanProperty(changes.flippable.currentValue);
    }
  }
}
