import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';

import { NavigationComponent } from '../../navigation.component';
import { NavigationService } from '../../navigation.service';
import { INavigationItem } from '../../navigation.types';

@Component({
  selector: 'app-navigation-aside-item',
  templateUrl: './aside.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationAsideItemComponent implements OnChanges, OnInit, OnDestroy {
  @Input() activeItemId: string;
  @Input() autoCollapse: boolean;
  @Input() item: INavigationItem;
  @Input() name: string;
  @Input() skipChildren: boolean;

  public active: boolean = false;

  private navigationComponent: NavigationComponent;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private navigationService: NavigationService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('activeItemId' in changes) {
      this.markIfActive(this.router.url);
    }
  }

  ngOnInit(): void {
    this.markIfActive(this.router.url);

    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event: NavigationEnd) => {
        this.markIfActive(event.urlAfterRedirects);
      });

    this.navigationComponent = this.navigationService.getComponent(this.name);

    this.navigationComponent.onRefreshed.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  public trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private hasActiveChild(item: INavigationItem, currentUrl: string): boolean {
    const children = item.children;

    if (!children) {
      return false;
    }

    for (const child of children) {
      if (child.children) {
        if (this.hasActiveChild(child, currentUrl)) {
          return true;
        }
      }

      if (child.type !== 'basic') {
        continue;
      }

      if (child.link && this.router.isActive(child.link, child.exactMatch || false)) {
        return true;
      }
    }

    return false;
  }

  private markIfActive(currentUrl: string): void {
    this.active = this.activeItemId === this.item.id;

    if (this.hasActiveChild(this.item, currentUrl)) {
      this.active = true;
    }

    this.changeDetectorRef.markForCheck();
  }
}
