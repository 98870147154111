import { combineLatest, delay, filter, map, Subject, take, takeUntil } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { MediaWatcherService } from 'app/shared/services/media-watcher.service';
import { HelperService } from '../../../shared/services/helper.service';
import { ThemeService } from 'app/shared/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public showSplashScreen = true;
  public navigationOpened = true;
  public scheme: 'dark' | 'light' = 'light';
  public theme: string;
  public isScreenSmall: boolean;
  public navigation$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(() => this.getNavigationConfig()),
  );
  public notifications$ = this.helperService.notifications;

  private unsubscribe$ = new Subject();

  constructor(
    private mediaWatcherService: MediaWatcherService,
    @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute,
    private helperService: HelperService,
    private themeService: ThemeService,
    private router: Router
  ) {
    this.initSplashScreen();
  }

  ngOnInit(): void {
    this.setupTheme();
  }

  public toggleNavigation(): void {
    this.navigationOpened = !this.navigationOpened;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private setupTheme(): void {
    this.mediaWatcherService.onMediaChange$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({matchingAliases}) => {
        this.isScreenSmall = !matchingAliases.includes('md');
        if (this.isScreenSmall) {
          this.navigationOpened = false;
        }
      });

    combineLatest([
      this.themeService.config$,
      this.mediaWatcherService.onMediaQueryChange$(['(prefers-color-scheme: dark)', '(prefers-color-scheme: light)'])
    ]).pipe(
      takeUntil(this.unsubscribe$),
      map(([config, mql]) => {
        const options = {
          scheme: config.scheme,
          theme: config.theme
        };

        if (config.scheme === 'auto') {
          options.scheme = mql.breakpoints['(prefers-color-scheme: dark)'] ? 'dark' : 'light';
        }

        return options;
      })
    ).subscribe(({scheme, theme}) => {
      this.scheme = scheme;
      this.theme = theme;

      this.updateScheme();
      this.updateTheme();
    });
  }

  private getNavigationConfig(): boolean | null {
    let child = this.activatedRoute.firstChild;

    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data && 'navigation' in child.snapshot.data) {
        return child.snapshot.data.navigation;
      } else {
        return true;
      }
    }

    return true;
  }

  private initSplashScreen(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1),
        delay(1000)
      )
      .subscribe(() => this.showSplashScreen = false);
  }

  private updateScheme(): void {
    this.document.body.classList.remove('light', 'dark');
    this.document.body.classList.add(this.scheme);
  }

  private updateTheme(): void {
    this.document.body.classList.forEach((className: string) => {
      if (className.startsWith('theme-')) {
        this.document.body.classList.remove(className, className.split('-')[1]);
      }
    });

    this.document.body.classList.add(this.theme);
  }
}
