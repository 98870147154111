import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { debounceTime, Subject, takeUntil } from 'rxjs';

import { LoadingService } from 'app/shared/services/loading.service';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'appLoadingBar'
})
export class LoadingBarComponent implements OnChanges, OnInit, OnDestroy {
  @Input() autoMode: boolean = true;

  public mode: 'determinate' | 'indeterminate';
  public progress: number = 0;
  public show: boolean = false;

  private unsubscribe$ = new Subject();

  constructor(
    private loadingService: LoadingService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('autoMode' in changes) {
      this.loadingService.setAutoMode(coerceBooleanProperty(changes.autoMode.currentValue));
    }
  }

  ngOnInit(): void {
    this.loadingService.mode$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => this.mode = value);

    this.loadingService.progress$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => this.progress = value);

    this.loadingService.show$
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100)
      )
      .subscribe(value => this.show = value);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
