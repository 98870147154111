import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxLocalStorageModule } from 'ngx-localstorage';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';

import { LoadingInterceptor } from 'app/shared/interceptors/loading.interceptor';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { AppComponent } from 'app/core/components/app/app.component';
import { UserService } from './modules/users/services/user.service';
import { SharedModule } from 'app/shared/shared.module';
import { appRoutes } from 'app/app-routing.module';
import { CoreModule } from 'app/core/core.module';

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};

export const getUser = (userService: UserService): any => (): any => userService.getUser();

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),
    CoreModule,
    SharedModule,
    NgxLocalStorageModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: getUser,
      deps: [UserService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'fill'}
    }
  ]
})
export class AppModule {
}
