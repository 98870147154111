import { LocalStorageService } from 'ngx-localstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { merge } from 'lodash-es';

import { themeConfig } from 'app/config/theme.config';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private configSubject = new BehaviorSubject(themeConfig);

  constructor(
    private localStorageService: LocalStorageService
  ) {
    const themeConfigLS = localStorageService.get('theme');

    if (!themeConfig) {
      localStorageService.set('theme', this.configSubject.getValue());
    } else {
      this.config = themeConfigLS;
    }
  }

  get config$(): Observable<any> {
    return this.configSubject.asObservable();
  }

  set config(value: any) {
    const config = merge({}, this.configSubject.getValue(), value);

    this.configSubject.next(config);
    this.localStorageService.set('theme', this.configSubject.getValue());
  }

  public reset(): void {
    this.configSubject.next(this.config);
  }
}
