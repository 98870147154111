import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { NavigationCollapsableItemComponent } from './components/collapsable/collapsable.component';
import { NavigationDividerItemComponent } from './components/divider/divider.component';
import { NavigationSpacerItemComponent } from './components/spacer/spacer.component';
import { NavigationAsideItemComponent } from './components/aside/aside.component';
import { NavigationBasicItemComponent } from './components/basic/basic.component';
import { NavigationGroupItemComponent } from './components/group/group.component';
import { NavigationComponent } from './navigation.component';
import { SharedModule } from '../../shared.module';

@NgModule({
  declarations: [
    NavigationAsideItemComponent,
    NavigationBasicItemComponent,
    NavigationCollapsableItemComponent,
    NavigationDividerItemComponent,
    NavigationGroupItemComponent,
    NavigationSpacerItemComponent,
    NavigationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    SharedModule,
  ],
  exports: [
    NavigationComponent
  ]
})
export class NavigationModule {
}
