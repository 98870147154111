import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { LoadingService } from 'app/shared/services/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  handleRequestsAutomatically: boolean;

  constructor(
    private loadingService: LoadingService
  ) {
    this.loadingService.auto$
      .subscribe((value) => {
        this.handleRequestsAutomatically = value;
      });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.handleRequestsAutomatically) {
      return next.handle(req);
    }

    this.loadingService.setLoadingStatus(true, req.url);

    return next.handle(req).pipe(
      finalize(() => {
        this.loadingService.setLoadingStatus(false, req.url);
      }));
  }
}
