import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Subject, takeUntil } from 'rxjs';

import { QuickChatService } from './quick-chat.service';
import { IChat } from './quick-chat.types';

@Component({
  selector: 'app-quick-chat',
  templateUrl: './quick-chat.component.html',
  styleUrls: ['./quick-chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'quickChat'
})
export class QuickChatComponent implements OnInit, OnDestroy {
  @ViewChild('messageInput') messageInput: ElementRef;

  public chat: IChat;
  public chats: IChat[];
  public opened: boolean = false;
  public selectedChat: IChat;

  private scrollStrategy: ScrollStrategy = this.scrollStrategyOptions.block();
  private overlay: HTMLElement;
  private unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private scrollStrategyOptions: ScrollStrategyOptions,
    private quickChatService: QuickChatService,
    private elementRef: ElementRef,
    private renderer2: Renderer2,
    private ngZone: NgZone
  ) {
  }

  @HostBinding('class') get classList(): any {
    return {
      'quick-chat-opened': this.opened
    };
  }

  @HostListener('input')
  @HostListener('ngModelChange')
  private resizeMessageInput(): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.messageInput.nativeElement.style.height = 'auto';

        this.messageInput.nativeElement.style.height = `${this.messageInput.nativeElement.scrollHeight}px`;
      });
    });
  }

  ngOnInit(): void {
    this.quickChatService.chat$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((chat: IChat) => {
        this.chat = chat;
      });

    this.quickChatService.chats$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((chats: IChat[]) => {
        this.chats = chats;
      });

    this.quickChatService.chat$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((chat: IChat) => {
        this.selectedChat = chat;
      });
  }

  public open(): void {
    if (this.opened) {
      return;
    }

    this.toggleOpened(true);
  }

  public close(): void {
    if (!this.opened) {
      return;
    }

    this.toggleOpened(false);
  }

  public toggle(): void {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  public selectChat(id: string): void {
    this.toggleOpened(true);

    this.quickChatService.getChatById(id).subscribe();
  }

  public trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private showOverlay(): void {
    this.hideOverlay();

    this.overlay = this.renderer2.createElement('div');

    if (!this.overlay) {
      return;
    }

    this.overlay.classList.add('quick-chat-overlay');

    this.renderer2.appendChild(this.elementRef.nativeElement.parentElement, this.overlay);

    this.scrollStrategy.enable();

    this.overlay.addEventListener('click', () => {
      this.close();
    });
  }

  private hideOverlay(): void {
    if (!this.overlay) {
      return;
    }

    if (this.overlay) {
      this.overlay.parentNode.removeChild(this.overlay);
      this.overlay = null;
    }

    this.scrollStrategy.disable();
  }

  private toggleOpened(open: boolean): void {
    this.opened = open;

    if (open) {
      this.showOverlay();
    } else {
      this.hideOverlay();
    }
  }
}
