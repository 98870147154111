<div
  *ngIf="!dismissible || dismissible && !dismissed"
  [@fadeIn]="!dismissed"
  [@fadeOut]="!dismissed"
  class="app-alert-container">

  <div
    *ngIf="appearance === 'border'"
    class="app-alert-border">
  </div>

  <div
    *ngIf="showIcon"
    class="app-alert-icon">

    <div class="app-alert-custom-icon">
      <ng-content select="[appAlertIcon]"></ng-content>
    </div>

    <div class="app-alert-default-icon">
      <mat-icon
        *ngIf="type === 'primary'"
        [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>

      <mat-icon
        *ngIf="type === 'accent'"
        [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>

      <mat-icon
        *ngIf="type === 'warn'"
        [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>

      <mat-icon
        *ngIf="type === 'basic'"
        [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>

      <mat-icon
        *ngIf="type === 'info'"
        [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>

      <mat-icon
        *ngIf="type === 'success'"
        [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>

      <mat-icon
        *ngIf="type === 'warning'"
        [svgIcon]="'heroicons_solid:exclamation'"></mat-icon>

      <mat-icon
        *ngIf="type === 'error'"
        [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>

    </div>
  </div>

  <div class="app-alert-content">
    <div class="app-alert-title">
      <ng-content select="[appAlertTitle]"></ng-content>
    </div>

    <div class="app-alert-message">
      <ng-content></ng-content>
    </div>

  </div>

  <button
    (click)="dismiss()"
    class="app-alert-dismiss-button"
    mat-icon-button>
    <mat-icon [svgIcon]="'heroicons_solid:x'"></mat-icon>
  </button>
</div>
