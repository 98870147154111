import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[appScrollReset]',
  exportAs: 'appScrollReset'
})
export class ScrollResetDirective implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();

  constructor(
    private elementRef: ElementRef,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.elementRef.nativeElement.scrollTop = 0;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
