<button
  [matMenuTriggerFor]="userActions"
  mat-icon-button>
  <span class="relative">
    <img
      alt=""
      *ngIf="showAvatar && user?.avatar"
      [src]="user?.avatar.url"
      class="w-7 h-7 rounded-full">
    <mat-icon
      *ngIf="!showAvatar || !user?.avatar"
      [svgIcon]="'heroicons_outline:user-circle'">
    </mat-icon>
    <span
      [ngClass]="{'mr-px mb-px': !showAvatar || !user?.avatar,
      'bg-green-500': user?.status === 'online',
      'bg-amber-500': user?.status === 'away',
      'bg-red-500': user?.status === 'busy',
      'bg-gray-400': user?.status === 'not-visible'}"
      class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
    ></span>
  </span>
</button>

<mat-menu
  #userActions="matMenu"
  [xPosition]="'before'">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>Signed in as</span>
      <span class="mt-1.5 text-md font-medium">{{user?.email}}</span>
    </span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item routerLink="settings">
    <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
    <span>Settings</span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button
    (click)="signOut()"
    mat-menu-item>
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>

