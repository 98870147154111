import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';

import { ShortcutsService } from './shortcuts.service';
import { IShortcut } from './shortcuts.types';

@Component({
  selector: 'app-shortcuts',
  templateUrl: './shortcuts.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShortcutsComponent implements OnInit, OnDestroy {
  @ViewChild('shortcutsOrigin') private shortcutsOrigin: MatButton;
  @ViewChild('shortcutsPanel') private shortcutsPanel: TemplateRef<any>;

  public mode: 'view' | 'modify' | 'add' | 'edit' = 'view';
  public shortcutForm = this.formBuilder.group({
    id: [null],
    label: ['', Validators.required],
    description: [''],
    icon: ['', Validators.required],
    link: ['', Validators.required],
    useRouter: ['', Validators.required]
  });
  public shortcuts: IShortcut[];

  private overlayRef: OverlayRef;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private shortcutsService: ShortcutsService,
    private viewContainerRef: ViewContainerRef,
    private formBuilder: UntypedFormBuilder,
    private overlay: Overlay
  ) {
  }

  ngOnInit(): void {
    this.shortcutsService.shortcuts$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((shortcuts: IShortcut[]) => {
        this.shortcuts = shortcuts;

        this.changeDetectorRef.markForCheck();
      });
  }

  public openPanel(): void {
    if (!this.shortcutsPanel || !this.shortcutsOrigin) {
      return;
    }

    this.mode = 'view';

    if (!this.overlayRef) {
      this.createOverlay();
    }

    this.overlayRef.attach(new TemplatePortal(this.shortcutsPanel, this.viewContainerRef));
  }

  public closePanel(): void {
    this.overlayRef.detach();
  }

  public changeMode(mode: 'view' | 'modify' | 'add' | 'edit'): void {
    this.mode = mode;
  }

  public newShortcut(): void {
    this.shortcutForm.reset();
    this.mode = 'add';
  }

  public editShortcut(shortcut: IShortcut): void {
    this.shortcutForm.reset(shortcut);
    this.mode = 'edit';
  }

  public save(): void {
    const shortcut = this.shortcutForm.value;

    if (shortcut.id) {
      this.shortcutsService.update(shortcut.id, shortcut).subscribe();
    } else {
      this.shortcutsService.create(shortcut).subscribe();
    }

    this.mode = 'modify';
  }

  public delete(): void {
    const shortcut = this.shortcutForm.value;
    this.shortcutsService.delete(shortcut.id).subscribe();
    this.mode = 'modify';
  }

  public trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();

    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  private createOverlay(): void {
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'app-backdrop-on-mobile',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.shortcutsOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom'
          }
        ])
    });

    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.detach();
    });
  }
}
