import {
  ActivatedRouteSnapshot, CanActivate, CanActivateChild,
  CanLoad, Route, Router, RouterStateSnapshot, UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';

import { UserService } from 'app/modules/users/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private userService: UserService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this.check(redirectUrl);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this.check(redirectUrl);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.check('/');
  }

  private check(redirectURL: string): Observable<boolean> {
    return this.userService.getUser().pipe(
      switchMap((user) => {
        if (!user) {
          this.router.navigate(['/auth/sign-in'], {queryParams: {redirectURL}});

          return of(false);
        }

        return of(true);
      }),
    );
  }
}
