import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { userRoles } from '../../../config/user-roles.config';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  encapsulation: ViewEncapsulation.None,
  exportAs: 'appTeams'
})
export class TeamsComponent implements OnInit {
  @Input() roles = userRoles;
  members: any[];

  constructor() {
  }

  ngOnInit(): void {
    this.members = [
      {
        avatar: 'assets/images/avatars/male.jpg',
        name: 'Mclaughlin Steele',
        email: 'mclaughlinsteele@mail.me',
        role: 'admin'
      },
      {
        avatar: 'assets/images/avatars/female.jpg',
        name: 'Laverne Dodson',
        email: 'lavernedodson@mail.ca',
        role: 'write'
      },
      {
        avatar: null,
        name: 'Trudy Berg',
        email: 'trudyberg@mail.us',
        role: 'read'
      },
      {
        avatar: 'assets/images/avatars/male.jpg',
        name: 'Lamb Underwood',
        email: 'lambunderwood@mail.me',
        role: 'read'
      },
      {
        avatar: 'assets/images/avatars/male.jpg',
        name: 'Mcleod Wagner',
        email: 'mcleodwagner@mail.biz',
        role: 'read'
      },
      {
        avatar: 'assets/images/avatars/female.jpg',
        name: 'Shannon Kennedy',
        email: 'shannonkennedy@mail.ca',
        role: 'read'
      }
    ];
  }
}
