import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { NavigationComponent } from '../../navigation.component';
import { NavigationService } from '../../navigation.service';
import { INavigationItem } from '../../navigation.types';

@Component({
  selector: 'app-navigation-spacer-item',
  templateUrl: './spacer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationSpacerItemComponent implements OnInit, OnDestroy {
  @Input() item: INavigationItem;
  @Input() name: string;

  private appNavigationComponent: NavigationComponent;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private navigationService: NavigationService
  ) {
  }

  ngOnInit(): void {
    this.appNavigationComponent = this.navigationService.getComponent(this.name);

    this.appNavigationComponent.onRefreshed.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
