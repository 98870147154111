import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IMessage } from './messages.types';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  private messages: ReplaySubject<IMessage[]> = new ReplaySubject<IMessage[]>(1);

  constructor(
    private httpClient: HttpClient
  ) {
  }

  get messages$(): Observable<IMessage[]> {
    return this.messages.asObservable();
  }

  public getAll(): Observable<IMessage[]> {
    return this.httpClient.get<IMessage[]>('api/common/messages').pipe(
      tap((messages) => {
        this.messages.next(messages);
      })
    );
  }

  public create(message: IMessage): Observable<IMessage> {
    return this.messages$.pipe(
      take(1),
      switchMap(messages => this.httpClient.post<IMessage>('api/common/messages', {message}).pipe(
        map((newMessage) => {
          this.messages.next([...messages, newMessage]);
          return newMessage;
        })
      ))
    );
  }

  public update(id: string, message: IMessage): Observable<IMessage> {
    return this.messages$.pipe(
      take(1),
      switchMap(messages => this.httpClient.patch<IMessage>('api/common/messages', {
        id,
        message
      }).pipe(
        map((updatedMessage: IMessage) => {
          const index = messages.findIndex(item => item.id === id);

          messages[index] = updatedMessage;

          this.messages.next(messages);

          return updatedMessage;
        })
      ))
    );
  }

  public delete(id: string): Observable<boolean> {
    return this.messages$.pipe(
      take(1),
      switchMap(messages => this.httpClient.delete<boolean>('api/common/messages', {params: {id}}).pipe(
        map((isDeleted: boolean) => {
          const index = messages.findIndex(item => item.id === id);

          messages.splice(index, 1);

          this.messages.next(messages);

          return isDeleted;
        })
      ))
    );
  }

  public markAllAsRead(): Observable<boolean> {
    return this.messages$.pipe(
      take(1),
      switchMap(messages => this.httpClient.get<boolean>('api/common/messages/mark-all-as-read').pipe(
        map((isUpdated: boolean) => {
          messages.forEach((message, index) => {
            messages[index].read = true;
          });

          this.messages.next(messages);

          return isUpdated;
        })
      ))
    );
  }
}
