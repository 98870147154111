import { Route } from '@angular/router';

import { NoAuthGuard } from './shared/guards/noAuth.guard';
import { AuthGuard } from './shared/guards/auth.guard';

export const appRoutes: Route[] = [
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: 'users'
  },
  {
    path: 'auth',
    loadChildren: (): any => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    data: {
      navigation: false
    }
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: (): any => import('./modules/users/users.module').then(m => m.UsersModule),
  },
  {
    path: 'companies',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: (): any => import('./modules/companies/companies.module').then(m => m.CompaniesModule),
  },
  {
    path: 'jobs',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: (): any => import('./modules/jobs/jobs.module').then(m => m.JobsModule),
  },
  {
    path: 'talents',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: (): any => import('./modules/talents/talents.module').then(m => m.TalentsModule),
  },
  {
    path: 'candidates',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: (): any => import('./modules/candidates/candidates.module').then(m => m.CandidatesModule),
  },
  {
    path: 'taxonomy',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: (): any => import('./modules/taxonomy/taxonomy.module').then(m => m.TaxonomyModule),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: (): any => import('./modules/settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'users',
  },
];
