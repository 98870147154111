<ng-container *ngIf="flippable">
  <div class="app-card-front">
    <ng-content select="[appCardFront]"></ng-content>
  </div>

  <div class="app-card-back">
    <ng-content select="[appCardBack]"></ng-content>
  </div>
</ng-container>

<ng-container *ngIf="!flippable">
  <ng-content></ng-content>

  <div
    *ngIf="expanded"
    [@expandCollapse]
    class="app-card-expansion">
    <ng-content select="[appCardExpansion]"></ng-content>
  </div>
</ng-container>
