import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { INotification } from './notifications.types';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private notifications: ReplaySubject<INotification[]> = new ReplaySubject<INotification[]>(1);

  constructor(
    private httpClient: HttpClient
  ) {
  }

  get notifications$(): Observable<INotification[]> {
    return this.notifications.asObservable();
  }

  public getAll(): Observable<INotification[]> {
    return this.httpClient.get<INotification[]>('api/common/notifications').pipe(
      tap((notifications) => {
        this.notifications.next(notifications);
      })
    );
  }

  public create(notification: INotification): Observable<INotification> {
    return this.notifications$.pipe(
      take(1),
      switchMap(notifications => this.httpClient.post<INotification>('api/common/notifications', {notification}).pipe(
        map((newNotification) => {
          this.notifications.next([...notifications, newNotification]);
          return newNotification;
        })
      ))
    );
  }

  public update(id: string, notification: INotification): Observable<INotification> {
    return this.notifications$.pipe(
      take(1),
      switchMap(notifications => this.httpClient.patch<INotification>('api/common/notifications', {
        id,
        notification
      }).pipe(
        map((updatedNotification: INotification) => {
          const index = notifications.findIndex(item => item.id === id);

          notifications[index] = updatedNotification;

          this.notifications.next(notifications);

          return updatedNotification;
        })
      ))
    );
  }

  public delete(id: string): Observable<boolean> {
    return this.notifications$.pipe(
      take(1),
      switchMap(notifications => this.httpClient.delete<boolean>('api/common/notifications', {params: {id}}).pipe(
        map((isDeleted: boolean) => {
          const index = notifications.findIndex(item => item.id === id);

          notifications.splice(index, 1);

          this.notifications.next(notifications);

          return isDeleted;
        })
      ))
    );
  }

  public markAllAsRead(): Observable<boolean> {
    return this.notifications$.pipe(
      take(1),
      switchMap(notifications => this.httpClient.get<boolean>('api/common/notifications/mark-all-as-read').pipe(
        map((isUpdated: boolean) => {
          notifications.forEach((notification, index) => {
            notifications[index].read = true;
          });

          this.notifications.next(notifications);

          return isUpdated;
        })
      ))
    );
  }
}
