<div class="app-navigation-wrapper">
  <div class="app-navigation-header">
    <ng-content select="[appNavigationHeader]"></ng-content>
  </div>

  <div
    appScrollbar
    [appScrollbarOptions]="{wheelPropagation: inner, suppressScrollX: true}"
    class="app-navigation-content"
    #navigationContent
  >
    <div class="app-navigation-content-header">
      <ng-content select="[appNavigationContentHeader]"></ng-content>
    </div>

    <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
      <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
        <ng-container *ngIf="item.type === 'aside'">
          <app-navigation-aside-item
            (click)="toggleAside(item)"
            [activeItemId]="activeAsideItemId"
            [autoCollapse]="autoCollapse"
            [item]="item"
            [name]="name"
            [skipChildren]="true"
          ></app-navigation-aside-item>
        </ng-container>

        <ng-container *ngIf="item.type === 'basic'">
          <app-navigation-basic-item
            [item]="item"
            [name]="name"
          ></app-navigation-basic-item>
        </ng-container>

        <ng-container *ngIf="item.type === 'collapsable'">
          <app-navigation-collapsable-item
            [autoCollapse]="autoCollapse"
            [item]="item"
            [name]="name"
          ></app-navigation-collapsable-item>
        </ng-container>

        <ng-container *ngIf="item.type === 'divider'">
          <app-navigation-divider-item
            [item]="item"
            [name]="name"
          ></app-navigation-divider-item>
        </ng-container>

        <ng-container *ngIf="item.type === 'group'">
          <app-navigation-group-item
            [autoCollapse]="autoCollapse"
            [item]="item"
            [name]="name"
          ></app-navigation-group-item>
        </ng-container>

        <ng-container *ngIf="item.type === 'spacer'">
          <app-navigation-spacer-item
            [item]="item"
            [name]="name"
          ></app-navigation-spacer-item>
        </ng-container>
      </ng-container>
    </ng-container>

    <div class="app-navigation-content-footer">
      <ng-content select="[appNavigationContentFooter]"></ng-content>
    </div>
  </div>

  <div class="app-navigation-footer">
    <ng-content select="[appNavigationFooter]"></ng-content>
  </div>
</div>

<ng-container *ngIf="activeAsideItemId">
  <div
    appScrollbar
    [appScrollbarOptions]="{wheelPropagation: false, suppressScrollX: true}"
    class="app-navigation-aside-wrapper"
    [@fadeInLeft]="position === 'left'"
    [@fadeInRight]="position === 'right'"
    [@fadeOutLeft]="position === 'left'"
    [@fadeOutRight]="position === 'right'"
  >
    <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
      <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
        <ng-container *ngIf="item.type === 'aside' && item.id === activeAsideItemId">
          <app-navigation-aside-item
            [autoCollapse]="autoCollapse"
            [item]="item"
            [name]="name"></app-navigation-aside-item>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
