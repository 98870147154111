<button
  (click)="toggleFullscreen()"
  [matTooltip]="tooltip || 'Toggle Fullscreen'"
  mat-icon-button>
  <ng-container [ngTemplateOutlet]="iconTpl || defaultIconTpl"></ng-container>
</button>

<ng-template #defaultIconTpl>
  <mat-icon [svgIcon]="'heroicons_outline:arrows-expand'"></mat-icon>
</ng-template>
