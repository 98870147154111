import {
  ActivatedRouteSnapshot, CanActivate, CanActivateChild,
  CanLoad, Route, Router, RouterStateSnapshot, UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';

import { UserService } from '../../modules/users/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private userService: UserService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return state.url === '/auth/sign-out' ? of(true) : this.check();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return state.url === '/auth/sign-out' ? of(true) : this.check();
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  private check(): Observable<boolean> {
    return this.userService.getUser().pipe(
      switchMap((user) => {
        if (user) {
          this.router.navigate(['']);

          return of(false);
        }

        return of(true);
      })
    );
  }
}
