import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';

import { HelperService } from '../services/helper.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private helperService: HelperService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 401 && error.status !== 403 && error.status !== 400) {
          this.helperService.showNotification({
            type: 'warn',
            title: 'Something went wrong!',
            message: error.message,
            dismissible: true,
            autoClose: 5000,
          });
        }
        return throwError(() => error);
      })
    );
  }
}
