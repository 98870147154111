import { catchError, Observable, of, tap } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SearchResultServiceInterface } from '../../../shared/store/search-result.service.interface';
import { environment } from '../../../../environments/environment';
import { SearchResult, User } from '../../../types';

@Injectable({
  providedIn: 'root'
})
export class UserService implements SearchResultServiceInterface<User> {
  private user: any = null;

  constructor(
    private cookieService: CookieService,
    private http: HttpClient,
  ) {
  }

  private get authenticated(): boolean {
    return this.cookieService.check('authenticated') && this.cookieService.get('authenticated') === 'true';
  }

  public getPermissions(): Observable<any> {
    return this.http.get(`${environment.api}/roles/permissions`);
  }

  public getRoles(params: any = {}): Observable<any> {
    return this.http.get(`${environment.api}/roles`, {params});
  }

  public createRole(body: any): Observable<any> {
    return this.http.post(`${environment.api}/roles`, body);
  }

  public deleteRole(id: number): Observable<any> {
    return this.http.delete(`${environment.api}/roles/${id}`);
  }

  public updateRole(id: number, body): Observable<any> {
    return this.http.put(`${environment.api}/roles/${id}`, body);
  }

  public loadEntities(params: any = {}): Observable<SearchResult<User>> {
    return this.http.get<SearchResult<User>>(`${environment.api}/users/list`, {params});
  }

  public getUser(): Observable<any> {
    if (!this.authenticated) {
      return of(null);
    }

    if (this.user) {
      return of(this.user);
    } else {
      return this.http.get(`${environment.api}/auth/me`).pipe(
        tap(user => this.user = user),
        catchError(() => of(null))
      );
    }
  }

  public updateEntity(userId: string, body: any): Observable<any> {
    return this.http.patch(`${environment.api}/users/${userId}`, body);
  }

  public deleteEntity(id: string): Observable<any> {
    return this.http.delete(`${environment.api}/users/${id}`);
  }

  public updatePermissions(permissions: any[]): Observable<any> {
    return this.http.patch(`${environment.api}/roles/permissions`, permissions);
  }
}
