<div
  [class.app-navigation-item-has-subtitle]="!!item.subtitle"
  [ngClass]="item.classes?.wrapper"
  class="app-navigation-item-wrapper">
  <div
    (click)="toggleCollapsable()"
    [matTooltip]="item.tooltip || ''"
    [ngClass]="{'app-navigation-item-disabled': item.disabled}"
    class="app-navigation-item">
    <ng-container *ngIf="item.icon">
      <mat-icon
        [ngClass]="item.classes?.icon"
        [svgIcon]="item.icon"
        class="app-navigation-item-icon"></mat-icon>
    </ng-container>

    <div class="app-navigation-item-title-wrapper">
      <div class="app-navigation-item-title">
        <span [ngClass]="item.classes?.title">
          {{item.title}}
        </span>
      </div>

      <ng-container *ngIf="item.subtitle">
        <div class="app-navigation-item-subtitle">
          <span [ngClass]="item.classes?.subtitle">
            {{item.subtitle}}
          </span>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="item.badge">
      <div class="app-navigation-item-badge">
        <div
          [ngClass]="item.badge.classes"
          class="app-navigation-item-badge-content">
          {{item.badge.title}}
        </div>
      </div>
    </ng-container>

    <mat-icon
      [svgIcon]="'heroicons_solid:chevron-right'"
      class="app-navigation-item-arrow icon-size-4">
    </mat-icon>
  </div>
</div>

<div
  *ngIf="!isCollapsed"
  @expandCollapse
  class="app-navigation-item-children">
  <ng-container *ngFor="let item of item.children; trackBy: trackByFn">
    <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
      <ng-container *ngIf="item.type === 'basic'">
        <app-navigation-basic-item
          [item]="item"
          [name]="name">
        </app-navigation-basic-item>
      </ng-container>

      <ng-container *ngIf="item.type === 'collapsable'">
        <app-navigation-collapsable-item
          [autoCollapse]="autoCollapse"
          [item]="item"
          [name]="name">
        </app-navigation-collapsable-item>
      </ng-container>

      <ng-container *ngIf="item.type === 'divider'">
        <app-navigation-divider-item
          [item]="item"
          [name]="name">
        </app-navigation-divider-item>
      </ng-container>

      <ng-container *ngIf="item.type === 'group'">
        <app-navigation-group-item
          [item]="item"
          [name]="name">
        </app-navigation-group-item>
      </ng-container>

      <ng-container *ngIf="item.type === 'spacer'">
        <app-navigation-spacer-item
          [item]="item"
          [name]="name">
        </app-navigation-spacer-item>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
