import { Observable, of, switchMap, tap } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { UserService } from '../../users/services/user.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private cookieService: CookieService,
    private userService: UserService,
    private http: HttpClient,
  ) {
  }

  get rememberMe(): boolean {
    return this.cookieService.get('rememberMe') === 'true';
  }

  public forgotPassword(email: string): Observable<any> {
    return this.http.post(`${environment.api}/auth/forgot-password`, email);
  }

  public resetPassword(password: string): Observable<any> {
    return this.http.post(`${environment.api}/auth/reset-password`, password);
  }

  public signIn(credentials: any): Observable<any> {
    return this.http.post(`${environment.api}/auth/admin/sign-in`, credentials).pipe(
      switchMap(({user}: any) => {
        this.setAuthenticated(credentials.rememberMe);
        return of(user);
      })
    );
  }

  public signOut(): Observable<any> {
    this.cookieService.delete('authenticated', '/');
    this.cookieService.delete('rememberMe', '/');

    return of(true);
  }

  public refreshToken(): Observable<any> {
    return this.http.get(`${environment.api}/auth/refresh`);
  }

  public logOut(): Observable<any> {
    return this.http.post(`${environment.api}/auth/log-out`, {})
      .pipe(tap(() => this.signOut()));
  }

  public setAuthenticated(rememberMe: boolean = this.rememberMe): void {
    this.cookieService.set('authenticated', 'true', rememberMe ? 7 : undefined, '/');
    this.cookieService.set('rememberMe', rememberMe.toString(),rememberMe ? 7 : undefined, '/');
  }
}
