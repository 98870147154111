import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';

import { IMessage } from './messages.types';
import { MessagesService } from './messages.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'appMessages'
})
export class MessagesComponent implements OnInit, OnDestroy {
  @ViewChild('messagesOrigin') private messagesOrigin: MatButton;
  @ViewChild('messagesPanel') private messagesPanel: TemplateRef<any>;

  public messages: IMessage[];
  public unreadCount: number = 0;

  private overlayRef: OverlayRef;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
    private messagesService: MessagesService,
    private overlay: Overlay
  ) {
  }

  ngOnInit(): void {
    this.messagesService.messages$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((messages: IMessage[]) => {

        this.messages = messages;

        this.calculateUnreadCount();

        this.changeDetectorRef.markForCheck();
      });
  }

  public openPanel(): void {
    if (!this.messagesPanel || !this.messagesOrigin) {
      return;
    }

    if (!this.overlayRef) {
      this.createOverlay();
    }

    this.overlayRef.attach(new TemplatePortal(this.messagesPanel, this.viewContainerRef));
  }

  public closePanel(): void {
    this.overlayRef.detach();
  }

  public markAllAsRead(): void {
    this.messagesService.markAllAsRead().subscribe();
  }

  public toggleRead(message: IMessage): void {
    message.read = !message.read;
    this.messagesService.update(message.id, message).subscribe();
  }

  public delete(message: IMessage): void {
    this.messagesService.delete(message.id).subscribe();
  }

  public trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();

    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  private createOverlay(): void {
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'app-backdrop-on-mobile',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.messagesOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom'
          }
        ])
    });

    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.detach();
    });
  }

  private calculateUnreadCount(): void {
    let count = 0;

    if (this.messages && this.messages.length) {
      count = this.messages.filter(message => !message.read).length;
    }

    this.unreadCount = count;
  }
}
