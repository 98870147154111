import { Injectable } from '@angular/core';

import { INavigationItem } from './navigation.types';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private componentRegistry: Map<string, any> = new Map<string, any>();
  private navigationStore: Map<string, INavigationItem[]> = new Map<string, any>();

  constructor() {
  }

  public registerComponent(name: string, component: any): void {
    this.componentRegistry.set(name, component);
  }

  public deregisterComponent(name: string): void {
    this.componentRegistry.delete(name);
  }

  public getComponent<T>(name: string): T {
    return this.componentRegistry.get(name);
  }

  public storeNavigation(key: string, navigation: INavigationItem[]): void {
    this.navigationStore.set(key, navigation);
  }

  public getNavigation(key: string): INavigationItem[] {
    return this.navigationStore.get(key) ?? [];
  }

  public deleteNavigation(key: string): void {
    if (!this.navigationStore.has(key)) {
      console.warn(`Navigation with the key '${key}' does not exist in the store.`);
    }

    this.navigationStore.delete(key);
  }

  public getFlatNavigation(navigation: INavigationItem[], flatNavigation: INavigationItem[] = []): INavigationItem[] {
    for (const item of navigation) {
      if (item.type === 'basic') {
        flatNavigation.push(item);
        continue;
      }

      if (item.type === 'aside' || item.type === 'collapsable' || item.type === 'group') {
        if (item.children) {
          this.getFlatNavigation(item.children, flatNavigation);
        }
      }
    }

    return flatNavigation;
  }

  public getItem(id: string, navigation: INavigationItem[]): INavigationItem | null {
    for (const item of navigation) {
      if (item.id === id) {
        return item;
      }

      if (item.children) {
        const childItem = this.getItem(id, item.children);

        if (childItem) {
          return childItem;
        }
      }
    }

    return null;
  }

  public getItemParent(
    id: string,
    navigation: INavigationItem[],
    parent: INavigationItem[] | INavigationItem
  ): INavigationItem[] | INavigationItem | null {
    for (const item of navigation) {
      if (item.id === id) {
        return parent;
      }

      if (item.children) {
        const childItem = this.getItemParent(id, item.children, item);

        if (childItem) {
          return childItem;
        }
      }
    }

    return null;
  }
}
