import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { debounceTime, filter, map, Subject, takeUntil } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UntypedFormControl } from '@angular/forms';

import { animations } from 'app/shared/animations';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  encapsulation: ViewEncapsulation.None,
  exportAs: 'appSearch',
  animations: animations
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy {
  @Input() appearance: 'basic' | 'bar' = 'basic';
  @Input() debounce: number = 300;
  @Input() minLength: number = 2;
  @Output() search: EventEmitter<any> = new EventEmitter<any>();

  public opened: boolean = false;
  public resultSets: any[];
  public searchControl: UntypedFormControl = new UntypedFormControl();
  private unsubscribe$ = new Subject();

  constructor(
    private elementRef: ElementRef,
    private httpClient: HttpClient,
    private renderer2: Renderer2
  ) {
  }

  @HostBinding('class') get classList(): any {
    return {
      'search-appearance-bar': this.appearance === 'bar',
      'search-appearance-basic': this.appearance === 'basic',
      'search-opened': this.opened
    };
  }

  @ViewChild('barSearchInput')
  set barSearchInput(value: ElementRef) {
    if (value) {
      setTimeout(() => {
        value.nativeElement.focus();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('appearance' in changes) {
      this.close();
    }
  }

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(this.debounce),
        takeUntil(this.unsubscribe$),
        map((value) => {
          if (!value || value.length < this.minLength) {
            this.resultSets = null;
          }

          return value;
        }),
        filter(value => value && value.length >= this.minLength)
      )
      .subscribe((value) => {
        this.httpClient.post('api/common/search', {query: value})
          .subscribe((resultSets: any) => {
            this.resultSets = resultSets;

            this.search.next(resultSets);
          });
      });
  }

  public onKeydown(event: KeyboardEvent): void {
    if (this.appearance === 'bar') {
      if (event.code === 'Escape') {
        this.close();
      }
    }
  }

  public open(): void {
    if (this.opened) {
      return;
    }

    this.opened = true;
  }

  public close(): void {
    if (!this.opened) {
      return;
    }

    this.searchControl.setValue('');

    this.opened = false;
  }

  public trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
