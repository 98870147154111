import { Observable, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private readonly onDismissSubject = new ReplaySubject(1);
  private readonly onShowSubject = new ReplaySubject(1);

  constructor() {
  }

  get onDismiss(): Observable<any> {
    return this.onDismissSubject.asObservable();
  }

  get onShow(): Observable<any> {
    return this.onShowSubject.asObservable();
  }

  public dismiss(name: string): void {
    if (!name) {
      return;
    }

    this.onDismissSubject.next(name);
  }

  public show(name: string): void {
    if (!name) {
      return;
    }

    this.onShowSubject.next(name);
  }
}
